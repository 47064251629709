.App {
  text-align: justify;
  text-justify: inter-word;
}

.App-logo {
  height: 50vmin;
  border-radius: 50%;
  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-content-align {
  display: flex;
  justify-items: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: inherit;
  max-width: 90%;
  padding-top: 2em;
}

.App-link {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3pt;
  font-size: 0.8em;
}

a:link, a:visited {
  color: #000000;
}
a:hover, a:active {
  color: #4444FF;
}

.social-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

.App-gradient-bar {
  display: inline-block;
  width: 100%;
  height: 0.5em;
  background: linear-gradient(90deg, black, #4444FF 70%);
  margin: 14px 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(330deg);
  }
}
