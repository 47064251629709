@font-face {
  font-family: 'Arnhem-Black';
  src: url('Arnhem-Black.eot');
  src: url('Arnhem-Black.eot?#iefix') format('embedded-opentype'),
       url('Arnhem-Black.ttf') format('truetype'),
       url('Arnhem-Black.woff') format('woff'),
       url('Arnhem-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaStd-Book';
  src: url('FuturaStd-Book.eot');
  src: url('FuturaStd-Book.eot?#iefix') format('embedded-opentype'),
       url('FuturaStd-Book.ttf') format('truetype'),
       url('FuturaStd-Book.woff') format('woff'),
       url('FuturaStd-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

