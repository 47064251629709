@import url("./fonts/fonts.css");

body, html {
  margin: 0;
  padding: 0;
  font-family: FuturaStd-Book, Futura, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: Arnhem-Black, Arnhem, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 2.5em;
  letter-spacing: -0.05em;
  margin: 0;
  padding-right: 20px;
}

p {
  font-size: 1em;
  margin: 0;
}